.kuber-login-page {
  height: 100%;
  padding: 32px;
}

.kuber-login-page .carousel {
  height: 100vh;
  display: flex;
  align-items: center;
}

.kuber-login-page .carousel div {
  width: 100%;
  text-align: center;
}

.kuber-login-page .carousel .ant-image {
  width: 40%;
}

.kuber-login-page .card-form {
  background-color: #ffffff30;
  border-radius: 16px;
}

.kuber-login-page h4 {
  color: #ffffff !important;
}

.kuber-login-page .ant-typography.ant-typography-secondary {
  color: #0000009c;
}

.kuber-login-page i {
  font-size: 1.3rem;
}
