.kuber-booking-page .timeline {
  margin-block: 32px;
}

.kuber-booking-page .card {
  background-color: var(--bg-container);
}

.kuber-booking-page .slot {
  width: 64px;
  height: 64px;
  border-radius: 16px;
  background-color: var(--bg-card-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-border);
  position: relative;
}

.kuber-booking-page .ant-btn.ant-btn-circle.ant-btn-sm {
  position: absolute;
  right: -5px;
  top: -5px;
  min-width: 20px;
  width: 20px;
  height: 20px;
  font-size: 10px;
}

.kuber-booking-page .slot:not(.booked):not(.selected):hover {
  cursor: pointer;
}

.kuber-booking-page .slot.booked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.kuber-booking-page .slot.selected {
  border-color: var(--primary-color);
}

.kuber-booking-page .ant-tabs-content-holder {
  padding-inline: 32px;
  background-color: var(--bg-body);
  border-left: 1px solid var(--color-border-secondary);
  border-right: 1px solid var(--color-border-secondary);
  border-bottom: 1px solid var(--color-border-secondary);
}

.kuber-booking-page .card p {
  margin-block-start: 0;
}



.kuber-booking-page .cover {
  border-radius: 10px;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.kuber-booking-page .cover::before {
  content: "";
  position: absolute;
  width: 90%;
  height: 100%;
  background-image: inherit;
  background-size: cover;
  z-index: -1;
  filter: blur(12px);
  opacity: 0.6;
  left: 50%;
  transform: translate(-50%, 5%);
}

.kuber-booking-page .cover img {
  border-radius: 10px;
}




.kuber-booking-page .countdown {
  text-align: center;
  position: fixed;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.kuber-booking-page .countdown-mobile {
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 901px) {
  .countdown-mobile {
    display: none;
  }
}

@media only screen and (max-width: 900px) {
  .countdown {
    display: none;
  }
}
