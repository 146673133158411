.kuber-survey-page .container {
  background-color: var(--bg-container);
  padding: 4px;
}
.kuber-survey-page .container .ant-card {
  border-radius: 0;
}

.kuber-survey-page .list {
  list-style: none;
  margin: 0 -16px;
  padding: 0;
}
.kuber-survey-page .list li {
  padding: 4px 16px;
  background-color: var(--primary-color);
  color: #fff;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.kuber-survey-page .list li small {
  padding-inline-start: 8px;
}

.kuber-survey-page .ant-pro-card-col.ant-pro-card-split-vertical {
  border-color: var(--color-border-secondary);
}
.kuber-survey-page .ant-pro-card .ant-pro-card-header-border {
  border-color: var(--color-border-secondary);
}

.kuber-survey-create-page .builder-form {
  padding: 16px;
  background-color: var(--bg-container);
  height: 500px;
  overflow: auto;
  border-radius: 8px;
}

.kuber-survey-create-page .permission {
  padding-block-start: 32px;
}

.kuber-survey-create-page .element {
  border-radius: 8px;
  border: 1px solid var(--color-border-secondary);
  background-color: var(--bg-container);
  padding: 8px;
}
.kuber-survey-create-page .element i {
  font-size: 1.3rem;
}

.kuber-survey-create-page .content {
  border: 1px dashed var(--color-border-secondary);
  padding: 16px;
}

.kuber-survey-create-page .drop-zone {
  flex: 0 0 auto;
  height: 16px;
}
.kuber-survey-create-page .drop-zone.active {
  background-color: red;
}
