.dark-theme {
  background: #212529;
  .ant-layout .ant-layout-sider {
    background: #212529;
  }
  .ant-layout-footer {
    background: #212529;
  }
  .ant-pro-card {
    background: #212529;
  }
  .right-sider-watchlist-item {
    background: #292e32;
    border: 1px solid #292e32;
  }
  .ant-layout-content {
    background: #1b1d21;
  }
  .header-logo {
    color: #ffffff;
    background: #212529;
  }
  .header-logo .header-curve-left {
    background: #1b1d21;
  }
  .header-logo .header-triangle-left {
    background: radial-gradient(
      180% 180% at right -65% top -65%,
      transparent 99%,
      #1b1d21
    );
  }
  .header-logo .header-curve-right {
    background: #1b1d21;
  }
  .header-logo .header-triangle-right {
    background: radial-gradient(
      180% 180% at left -65% top -65%,
      transparent 99%,
      #1b1d21
    );
  }
  .right-sider {
    margin-block: 1px;
    background: #212529;
  }
  .right-sider-card {
    background: #292e32;
    border: 1px solid #32383e;
  }
  .ant-pro-table-list-toolbar-setting-item {
    color: #ffffff;
  }
  .ant-page-header .ant-page-header-heading-title {
    color: #ffffff;
  }
  .ant-pro-checkcard-checked {
    background-color: #212529;
  }
}
