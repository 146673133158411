.kuber-qr-page {
  height: 100%;
  padding: 32px;
}

.kuber-qr-page .card {
  background-color: #ffffff30;
  border-radius: 16px;
}

.kuber-qr-page h4 {
  color: #ffffff !important;
}

.kuber-qr-page .ant-typography.ant-typography-secondary {
  color: #0000009c;
}
