.light-theme {
  background: #ffffff;
  .ant-layout .ant-layout-sider {
    background: #ffffff;
  }
  .ant-layout-footer {
    background: #ffffff;
  }
  .ant-pro-card {
    background: #ffffff;
  }
  .right-sider-watchlist-item {
    background: #f4f6f9;
    border: 1px solid #f3f3f3;
  }
  .ant-layout-content {
    border: 1px solid #f3f3f3;
    background: #f5f7f9;
  }
  .header-logo {
    color: #333333;
    background: #ffffff;
  }
  .header-logo .header-curve-left {
    background: #f5f7f9;
  }
  .header-logo .header-triangle-left {
    background: radial-gradient(
      180% 180% at right -65% top -65%,
      transparent 99%,
      #f5f7f9
    );
  }
  .header-logo .header-curve-right {
    background: #f5f7f9;
  }
  .header-logo .header-triangle-right {
    background: radial-gradient(
      180% 180% at left -65% top -65%,
      transparent 99%,
      #f5f7f9
    );
  }
  .right-sider {
    background: #ffffff;
  }
  .right-sider-card {
    background: #f4f6f9;
    border: 1px solid #f3f3f3;
  }
  .ant-pro-table-list-toolbar-setting-item {
    color: #212529;
  }
  .ant-page-header .ant-page-header-heading-title {
    color: #333333;
  }
  .ant-pro-checkcard-checked {
    background-color: #ffffff;
  }
}
