.kuber-random-page {
  background-size: cover;
  background-position: center;
  display: table;
  width: 100%;
  height: 100vh;
}

.kuber-random-page .topic {
  margin-block: 24px;
}
.event-body {
  padding: 24px 24px 72px 24px;
  display: table-cell;
  vertical-align: middle;
  position: relative;
}
.event-body h1,
.event-body h2,
.event-body h3,
.event-body h4,
.event-body h5 {
  margin: 0 !important;
  color: #ffffff !important;
}

.event-carousel {
  width: 300px;
  margin-bottom: 32px;

  .slick-prev {
    left: -95px;
  }
  .slick-next {
    right: -45px;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 4rem;
    color: #ffffff;
  }
}

.event-card-prize {
  position: relative;
  z-index: 0;
  border-radius: 16px;
  .ant-card {
    background-color: #3f574b;
  }
}

.event-card-prize:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 1;
  border-radius: 16px;
}

.event-card-winner {
  position: relative;
  z-index: 0;
  border-radius: 16px;
  .ant-card {
    background-color: #3f574b;
  }
  .ant-list {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  .ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-description {
    color: #ffffff;
  }
}

.event-card-winner:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 1;
  border-radius: 16px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.event-button {
  &:hover {
    background: var(--primary-color);
    color: #fff;
    box-shadow: 0 0 5px var(--primary-color), 0 0 25px var(--primary-color),
      0 0 50px var(--primary-color), 0 0 200px var(--primary-color);
    -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
  }
  position: relative;
  display: inline-block;
  padding: 25px 30px;
  color: var(--primary-color);
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.5s;
  letter-spacing: 4px;
  overflow: hidden;
  span {
    position: absolute;
    display: block;
  }
  span:nth-child(1) {
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, var(--primary-color));
    animation: animate1 1s linear infinite;
  }
  span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, var(--primary-color));
    animation: animate2 1s linear infinite;
    animation-delay: 0.25s;
  }
  span:nth-child(3) {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, var(--primary-color));
    animation: animate3 1s linear infinite;
    animation-delay: 0.5s;
  }
  span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, var(--primary-color));
    animation: animate4 1s linear infinite;
    animation-delay: 0.75s;
  }
}

.event-button-cancel {
  &:hover {
    background: #ff4d4f;
    color: #fff;
    box-shadow: 0 0 5px #ff4d4f, 0 0 25px #ff4d4f, 0 0 50px #ff4d4f,
      0 0 200px #ff4d4f;
    -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
  }
  position: relative;
  display: inline-block;
  padding: 25px 30px;
  color: #ff4d4f;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.5s;
  letter-spacing: 4px;
  overflow: hidden;
  span {
    position: absolute;
    display: block;
  }
  span:nth-child(1) {
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #ff4d4f);
    animation: animate1 1s linear infinite;
  }
  span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #ff4d4f);
    animation: animate2 1s linear infinite;
    animation-delay: 0.25s;
  }
  span:nth-child(3) {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #ff4d4f);
    animation: animate3 1s linear infinite;
    animation-delay: 0.5s;
  }
  span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #ff4d4f);
    animation: animate4 1s linear infinite;
    animation-delay: 0.75s;
  }
}

@keyframes animate1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}
@keyframes animate2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}
@keyframes animate3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}
@keyframes animate4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}

.event-card-winner-avatar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 10px;
}

.event-card-winner-avatar .ant-image {
  width: 100% !important;
}
.event-card-winner-prize {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 30%;
  margin: 10px;
  border: 2px solid #fff;
  border-radius: 5px 0 0 5px;
  overflow: hidden;
}

.event-card-winner-empty {
  position: relative;
}

.event-card-nft-blur {
  position: relative;
}

.event-card-nft {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.thanks {
  font-size: 10vmin;
  text-align: center;
  color: #fff;
  height: 25vh;
  line-height: 25vh;
}

.thanks {
  animation: blink 4.5s infinite alternate;
}

@keyframes blink {
  0%,
  19%,
  21%,
  23%,
  64%,
  66%,
  100% {
    text-shadow: 0 0 0.5vmin #fff, 0 0 1vmin #fff, 0 0 2vmin #fff,
      0 0 4vmin #0f0, 0 0 8vmin #0f0, 0 0 9vmin #0f0, 0 0 10vmin #0f0,
      0 0 15vmin #0f0;
  }
  20%,
  22%,
  65% {
    text-shadow: none;
  }
}
