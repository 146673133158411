.kuber-item-page-image {
  border-radius: 10px;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.kuber-item-page-image::before {
  content: "";
  position: absolute;
  width: 90%;
  height: 100%;
  background-image: inherit;
  background-size: cover;
  z-index: -1;
  filter: blur(12px);
  opacity: 0.6;
  left: 50%;
  transform: translate(-50%, 5%);
}

.kuber-item-page-image img {
  border-radius: 10px;
}
