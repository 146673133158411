.kuber-dao-detail-page > .cover {
  border-radius: 10px;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.kuber-dao-detail-page > .cover::before {
  content: "";
  position: absolute;
  width: 95%;
  height: 100%;
  background-image: inherit;
  background-size: cover;
  z-index: -1;
  filter: blur(12px);
  opacity: 0.6;
  left: 50%;
  transform: translate(-50%, 3%);
}

.kuber-dao-detail-page .avatar {
  position: relative;
  z-index: 1;
  margin-top: -70px;
  margin-bottom: 20px;
  border: 4px solid;
  width: fit-content;
  border-radius: 50%;
}
.kuber-dao-detail-page .avatar .ant-avatar {
  border: 0;
}

.kuber-dao-detail-page .card-member {
  position: relative;
  overflow: hidden;
}
.kuber-dao-detail-page .card-member .ant-card-body {
  padding-inline-start: 40px;
  padding-block-start: 8px;
  padding-block-end: 8px;
}
.kuber-dao-detail-page .card-member .cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 100%;
}

.kuber-dao-detail-page .card-member .cover .ant-image-img {
  object-fit: cover;
  object-position: center center;
  width: 60px;
  height: 60px;
}

.kuber-dao-page .card-leader {
  color: #ffffff;
}
.kuber-dao-page .card-leader .ant-divider-vertical {
  height: 1.7em;
}
.kuber-dao-page .card-leader:not(.other) .ant-divider-vertical {
  border-color: #ffffff78;
}
.kuber-dao-page .card-leader h4 {
  margin: 0;
  background-color: #0000001c;
  line-height: 80px;
  width: 32px;
  text-align: center;
}
.kuber-dao-page .card-leader:not(.other) h4 {
  color: #ffffff !important;
}
.kuber-dao-page .card-leader strong {
  font-size: 1.1rem;
}

.kuber-dao-page .card-leader.other small {
  color: #878a99 !important;
}

.kuber-dao-page .card-leader:not(.other) strong {
  color: #ffffff !important;
}

.kuber-dao-page .card-leader.gold {
  background-color: #ffd658;
}
.kuber-dao-page .card-leader.silver {
  background-color: #c5ced6;
}
.kuber-dao-page .card-leader.bronze {
  background-color: #de8a54;
}
