.kuber-leaderboard-page .avatar {
  position: relative;
  z-index: 1;
  margin-top: -10px;
  margin-bottom: 20px;
  border: 4px solid var(--bg-body);
  width: fit-content;
  border-radius: 50%;
}
.kuber-leaderboard-page .avatar > .ant-avatar {
  width: 100px;
  height: 100px;
}
.kuber-leaderboard-page .rank {
  background-color: var(--bg-body);
  width: fit-content;
  border-radius: 50%;
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
}

.kuber-leaderboard-page .rank > .ant-avatar {
  width: 40px;
  height: 40px;
}

@media only screen and (max-width: 900px) {
  .kuber-leaderboard-page .avatar > .ant-avatar {
    width: 80px;
    height: 80px;
  }
  .kuber-leaderboard-page .rank > .ant-avatar {
    width: 30px;
    height: 30px;
  }
}
