@import "https://pro.fontawesome.com/releases/v5.15.4/css/all.css";

@font-face {
  font-family: "GraphikTH";
  src: url("../fonts/Graphik/GraphikTH-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "GraphikTH";
  src: url("../fonts/Graphik/GraphikTH-Medium.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "GraphikTH";
  src: url("../fonts/Graphik/GraphikTH-Bold.otf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Kanit";
  src: url("../fonts/Kanit/Kanit-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Kanit";
  src: url("../fonts/Kanit/Kanit-Medium.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Kanit";
  src: url("../fonts/Kanit/Kanit-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
:root {
  --primary-color: #24d37d;
}

.light-theme {
  --bg-scrollbar: #dfe5ea;
  --color-border: #f3f3f3;
  --color-border-secondary: #e9ebec;
  --color-title: #495057;
  --bg-body: #ffffff;
  --bg-card-secondary: #f4f6f9;
  --bg-container: #f5f7f9;
}

.dark-theme {
  --bg-scrollbar: #373a3f;
  --color-border: #32383e;
  --color-border-secondary: #2a2f35;
  --color-title: #ffffff;
  --bg-body: #212529;
  --bg-card-secondary: #292e32;
  --bg-container: #1b1d21;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  background: inherit;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--bg-scrollbar);
}
::-webkit-scrollbar-thumb:hover {
  background: var(--bg-scrollbar);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

.ant-layout-header {
  line-height: 36px;
  height: 36px;
  padding-inline: 16px;
}
.ant-layout-header-mobile {
  height: auto;
  padding: 0;
  display: none;
}
.ant-layout-header-mobile > div {
  padding-inline: 16px;
}
.ant-layout .ant-layout-sider {
  height: calc(100vh - 116px);
  margin-block: 16px;
  overflow: auto;
}
.ant-layout-footer {
  padding: 0 16px 0 0;
  display: flex;
}
.footer-mode {
  width: 80px;
  text-align: center;
  line-height: 48px;
}
.footer-body {
  text-align: center;
  height: 48px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.footer-body-mobile {
  text-align: center;
  height: 48px;
  display: flex;
  flex: 1;
  justify-content: end;
  align-items: center;
}

.ant-layout-content {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
}

.header-logo {
  padding: 2px 20px;
  height: 72px;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1px;
  position: relative;
}
.header-logo .header-curve-left {
  width: 26px;
  height: 36px;
  bottom: 0;
  left: 0;
  position: absolute;
  border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
}

.header-logo .header-triangle-left {
  position: absolute;
  bottom: 0;
  left: 25px;
  width: 12px;
  height: 12px;
}
.header-logo .header-curve-right {
  width: 26px;
  height: 36px;
  bottom: 0;
  right: 0;
  position: absolute;
  border-radius: 100% 0% 0% 100% / 99% 100% 0% 1%;
}
.header-logo .header-triangle-right {
  position: absolute;
  bottom: 0;
  right: 25px;
  width: 12px;
  height: 12px;
}

.content-main {
  width: calc(100vw - 320px);
}
.content-main-header {
  padding: 10px 16px;
}
.content-main-body {
  height: calc(100vh - 152px);
  overflow-y: auto;
  overflow-x: hidden;
}

.right-sider {
  width: 240px;
}
.right-sider-watchlist {
  padding: 12px;
}
.right-sider-watchlist-item {
  padding: 0 10px;
  border-radius: 6px;
}
.right-sider-latest {
  height: calc(100vh - 238px);
  overflow: auto;
}
.right-sider .ant-pro-card {
  background: none;
}
.right-sider .ant-pro-card .ant-pro-card-body {
  padding-inline: 12px;
}
.right-sider .ant-list-split .ant-list-item {
  border: 0;
}
.right-sider .ant-list .ant-list-item {
  padding: 6px 0;
}
.right-sider .ant-pro-table-list-toolbar-container-mobile {
  flex-direction: initial;
  padding: 0;
}
.right-sider .ant-list-item-meta-title {
  font-weight: 400;
}

.ant-pro-page-container-children-container {
  padding: 16px;
}

.ant-pro-page-container .ant-pro-page-container-warp-page-header {
  padding-inline-start: 16px;
  padding-inline-end: 16px;
}
.ant-table-wrapper .ant-table.ant-table-small .ant-table-thead > tr > th {
  padding: 8px 16px;
}
.ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody > tr > td {
  padding: 8px 16px;
}
.ant-pro-form-login-page-notice {
  align-items: center;
  justify-content: center;
}
.ant-pro-form-login-page {
  background: #24d37d;
}
.ant-pro-form-login-page-container {
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 0.25);
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1) !important;
}

.wallet-cover {
  border-radius: 10px;
  background-size: cover;
  position: relative;
  z-index: 1;
  height: 140px;
  margin: 0 16px 24px;
  padding: 6px 12px 0;
}
.wallet-cover::before {
  content: "";
  position: absolute;
  width: 95%;
  height: 100%;
  background-image: inherit;
  background-size: cover;
  z-index: -1;
  filter: blur(12px);
  opacity: 0.6;
  left: 50%;
  transform: translate(-50%, 3%);
}
.wallet-cover small {
  color: #ffffff;
  font-size: 0.5rem;
  text-shadow: 1px 1px 2px #0000006b;
}
.wallet-cover strong {
  color: #ffffff;
  font-size: 0.6rem;
  text-shadow: 1px 1px 2px #0000006b;
}
.wallet-cover h3 {
  color: #ffffff;
  text-shadow: 1px 1px 2px #0000006b;
}
.wallet-cover .ant-typography-copy {
  color: #ffffff;
}
.ant-carousel .slick-list {
  margin: 0 -16px;
  overflow: unset;
}
.ant-table-wrapper .ant-table-tbody > tr:last-child > td {
  border: 0;
}
.ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: none !important;
}
.ant-drawer .ant-drawer-body {
  overflow-x: hidden;
}
.ant-statistic .ant-statistic-content .ant-statistic-content-value {
  font-weight: 600;
}
.ant-pro-checkcard-content {
  padding-inline: 16px;
}

.preloader {
  display: flex;
  animation: tiltSpin 8s linear infinite;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 17em;
  height: 17em;
}

.preloader,
.preloader__ring {
  transform-style: preserve-3d;
}
.preloader__ring {
  animation-name: spin;
  animation-duration: 4s;
  animation-timing-function: inherit;
  animation-iteration-count: inherit;
  font-size: 2em;
  position: relative;
  height: 3rem;
  width: 1.5rem;
}
.preloader__ring:nth-child(even) {
  animation-direction: reverse;
}
.preloader__sector {
  color: #24d37d;
  font-weight: 600;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  text-transform: uppercase;
  transform: translateZ(7rem);
}
.preloader__sector,
.preloader__sector:empty:before {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.preloader__sector:empty:before {
  background: linear-gradient(
    transparent 45%,
    currentColor 45% 55%,
    transparent 55%
  );
  content: "";
}
.preloader__sector:nth-child(2) {
  transform: rotateY(12deg) translateZ(7rem);
}
.preloader__sector:nth-child(3) {
  transform: rotateY(24deg) translateZ(7rem);
}
.preloader__sector:nth-child(4) {
  transform: rotateY(36deg) translateZ(7rem);
}
.preloader__sector:nth-child(5) {
  transform: rotateY(48deg) translateZ(7rem);
}
.preloader__sector:nth-child(6) {
  transform: rotateY(60deg) translateZ(7rem);
}
.preloader__sector:nth-child(7) {
  transform: rotateY(72deg) translateZ(7rem);
}
.preloader__sector:nth-child(8) {
  transform: rotateY(84deg) translateZ(7rem);
}
.preloader__sector:nth-child(9) {
  transform: rotateY(96deg) translateZ(7rem);
}
.preloader__sector:nth-child(10) {
  transform: rotateY(108deg) translateZ(7rem);
}
.preloader__sector:nth-child(11) {
  transform: rotateY(120deg) translateZ(7rem);
}
.preloader__sector:nth-child(12) {
  transform: rotateY(132deg) translateZ(7rem);
}
.preloader__sector:nth-child(13) {
  transform: rotateY(144deg) translateZ(7rem);
}
.preloader__sector:nth-child(14) {
  transform: rotateY(156deg) translateZ(7rem);
}
.preloader__sector:nth-child(15) {
  transform: rotateY(168deg) translateZ(7rem);
}
.preloader__sector:nth-child(16) {
  transform: rotateY(180deg) translateZ(7rem);
}
.preloader__sector:nth-child(17) {
  transform: rotateY(192deg) translateZ(7rem);
}
.preloader__sector:nth-child(18) {
  transform: rotateY(204deg) translateZ(7rem);
}
.preloader__sector:nth-child(19) {
  transform: rotateY(216deg) translateZ(7rem);
}
.preloader__sector:nth-child(20) {
  transform: rotateY(228deg) translateZ(7rem);
}
.preloader__sector:nth-child(21) {
  transform: rotateY(240deg) translateZ(7rem);
}
.preloader__sector:nth-child(22) {
  transform: rotateY(252deg) translateZ(7rem);
}
.preloader__sector:nth-child(23) {
  transform: rotateY(264deg) translateZ(7rem);
}
.preloader__sector:nth-child(24) {
  transform: rotateY(276deg) translateZ(7rem);
}
.preloader__sector:nth-child(25) {
  transform: rotateY(288deg) translateZ(7rem);
}
.preloader__sector:nth-child(26) {
  transform: rotateY(300deg) translateZ(7rem);
}
.preloader__sector:nth-child(27) {
  transform: rotateY(312deg) translateZ(7rem);
}
.preloader__sector:nth-child(28) {
  transform: rotateY(324deg) translateZ(7rem);
}
.preloader__sector:nth-child(29) {
  transform: rotateY(336deg) translateZ(7rem);
}
.preloader__sector:nth-child(30) {
  transform: rotateY(348deg) translateZ(7rem);
}

.ant-picker-range {
  width: 100%;
}
.ant-menu-inline-collapsed > .ant-menu-item {
  padding-inline: 0;
}
.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon {
  width: 100%;
  text-align: center;
  display: block;
}
.ant-empty {
  margin-block: 16px;
}

@keyframes tiltSpin {
  from {
    transform: rotateY(0) rotateX(30deg);
  }
  to {
    transform: rotateY(1turn) rotateX(30deg);
  }
}
@keyframes spin {
  from {
    transform: rotateY(0);
  }
  to {
    transform: rotateY(1turn);
  }
}

.ant-pro-table-list-toolbar-left {
  max-width: none;
}
.ant-pro-table-list-toolbar-title {
  color: var(--color-title);
}
.ant-tabs-nav-operations {
  display: none !important;
}

@import "./light.css";
@import "./dark.css";

@media only screen and (max-width: 991px) {
  .right-sider {
    display: none;
  }
  .ant-layout .ant-layout-sider {
    display: none;
  }
  .content-main {
    width: 100vw;
  }
  .ant-pro-form-login-page-notice {
    display: none;
  }
}

.bg-bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.bg-bubbles li {
  position: absolute;
  list-style: none;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.12);
  bottom: -50px;
  animation: square 20s infinite;
  transition-timing-function: linear;
}

.bg-bubbles li:nth-child(1) {
  left: 10%;
}
.bg-bubbles li:nth-child(2) {
  left: 20%;
  width: 120px;
  height: 120px;
  animation-delay: 2s;
  animation-duration: 17s;
}
.bg-bubbles li:nth-child(3) {
  left: 25%;
  animation-delay: 4s;
}
.bg-bubbles li:nth-child(4) {
  left: 40%;
  width: 80px;
  height: 80px;
  animation-duration: 22s;
}
.bg-bubbles li:nth-child(5) {
  left: 70%;
  width: 90px;
  height: 90px;
}
.bg-bubbles li:nth-child(6) {
  left: 70%;
  width: 120px;
  height: 120px;
  animation-delay: 3s;
}
.bg-bubbles li:nth-child(7) {
  left: 32%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}
.bg-bubbles li:nth-child(8) {
  left: 55%;
  width: 80px;
  height: 80px;
  animation-delay: 15s;
  animation-duration: 40s;
}
.bg-bubbles li:nth-child(9) {
  left: 25%;
  width: 50px;
  height: 50px;
  animation-delay: 2s;
  animation-duration: 40s;
}
.bg-bubbles li:nth-child(10) {
  left: 90%;
  width: 140px;
  height: 140px;
  animation-delay: 11s;
}

.ant-pro-form-login-page-notice-activity-subTitle {
  text-align: center;
}

.ant-pro-form-login-page-notice-activity-subTitle {
  height: 100vh;
  display: flex;
  align-items: center;
}

@keyframes square {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-1000px) rotate(600deg);
  }
}

@media only screen and (min-width: 901px) {
  .footer-body-mobile {
    display: none;
  }
}

@media only screen and (max-width: 900px) {
  .ant-layout-header {
    display: none;
  }
  .ant-layout-header-mobile {
    display: block;
  }
  .content-main-header {
    display: none;
  }
  .content-main-body {
    height: calc(100vh - 120px);
    width: 100vw;
  }
  .footer-body {
    display: none;
  }
}

.container-waiting {
  width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 32px auto;
}

.card-comment {
  background-color: var(--bg-card-secondary);
  border-color: var(--color-border);
}
.card-comment .ant-card-body {
  padding: 10px 12px;
}
.ant-tabs-top > .ant-tabs-nav {
  margin: 0;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border: 0;
}

.📦 {
  width: 33px;
  height: 33px;
  position: relative;
  display: block;
  transform-origin: -50% center;
  border-radius: 15%;
}
.📦:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 15%;
}
.📦:nth-child(1) {
  animation: slide 1.5s ease-in-out infinite alternate;
}
.📦:nth-child(1):after {
  animation: color-change 1.5s ease-in-out infinite alternate;
}
.📦:nth-child(2) {
  animation: flip-1 1.5s ease-in-out infinite alternate;
}
.📦:nth-child(2):after {
  animation: squidge-1 1.5s ease-in-out infinite alternate;
}
.📦:nth-child(3) {
  animation: flip-2 1.5s ease-in-out infinite alternate;
}
.📦:nth-child(3):after {
  animation: squidge-2 1.5s ease-in-out infinite alternate;
}
.📦:nth-child(4) {
  animation: flip-3 1.5s ease-in-out infinite alternate;
}
.📦:nth-child(4):after {
  animation: squidge-3 1.5s ease-in-out infinite alternate;
}
.📦:nth-child(5) {
  animation: flip-4 1.5s ease-in-out infinite alternate;
}
.📦:nth-child(5):after {
  animation: squidge-4 1.5s ease-in-out infinite alternate;
}
.📦:nth-child(2):after {
  background-color: #af87ce;
}
.📦:nth-child(3):after {
  background-color: #ea1a7f;
}
.📦:nth-child(4):after {
  background-color: #fec603;
}
.📦:nth-child(5):after {
  background-color: #a8f387;
}

.policy {
  background-color: var(--bg-card-secondary);
  padding: 16px;
  border-radius: 16px 0 0 16px;
  height: 300px;
  overflow: auto;
}

@keyframes slide {
  0% {
    transform: translatex(0vw);
  }
  100% {
    transform: translatex(calc(250px - (33px * 1.25)));
  }
}

@keyframes color-change {
  0% {
    background-color: #16d6fa;
  }
  100% {
    background-color: #16d6fa;
  }
}

@keyframes flip-1 {
  0%,
  15% {
    transform: rotate(0);
  }
  35%,
  100% {
    transform: rotate(-180deg);
  }
}

@keyframes squidge-1 {
  5% {
    transform-origin: center bottom;
    transform: scalex(1) scaley(1);
  }
  15% {
    transform-origin: center bottom;
    transform: scalex(1.3) scaley(0.7);
  }
  25%,
  20% {
    transform-origin: center bottom;
    transform: scalex(0.8) scaley(1.4);
  }
  55%,
  100% {
    transform-origin: center top;
    transform: scalex(1) scaley(1);
  }
  40% {
    transform-origin: center top;
    transform: scalex(1.3) scaley(0.7);
  }
}

@keyframes flip-2 {
  0%,
  30% {
    transform: rotate(0);
  }
  50%,
  100% {
    transform: rotate(-180deg);
  }
}

@keyframes squidge-2 {
  20% {
    transform-origin: center bottom;
    transform: scalex(1) scaley(1);
  }
  30% {
    transform-origin: center bottom;
    transform: scalex(1.3) scaley(0.7);
  }
  40%,
  35% {
    transform-origin: center bottom;
    transform: scalex(0.8) scaley(1.4);
  }
  70%,
  100% {
    transform-origin: center top;
    transform: scalex(1) scaley(1);
  }
  55% {
    transform-origin: center top;
    transform: scalex(1.3) scaley(0.7);
  }
}

@keyframes flip-3 {
  0%,
  45% {
    transform: rotate(0);
  }
  65%,
  100% {
    transform: rotate(-180deg);
  }
}

@keyframes squidge-3 {
  35% {
    transform-origin: center bottom;
    transform: scalex(1) scaley(1);
  }
  45% {
    transform-origin: center bottom;
    transform: scalex(1.3) scaley(0.7);
  }
  55%,
  50% {
    transform-origin: center bottom;
    transform: scalex(0.8) scaley(1.4);
  }
  85%,
  100% {
    transform-origin: center top;
    transform: scalex(1) scaley(1);
  }
  70% {
    transform-origin: center top;
    transform: scalex(1.3) scaley(0.7);
  }
}

@keyframes flip-4 {
  0%,
  60% {
    transform: rotate(0);
  }
  80%,
  100% {
    transform: rotate(-180deg);
  }
}

@keyframes squidge-4 {
  50% {
    transform-origin: center bottom;
    transform: scalex(1) scaley(1);
  }
  60% {
    transform-origin: center bottom;
    transform: scalex(1.3) scaley(0.7);
  }
  70%,
  65% {
    transform-origin: center bottom;
    transform: scalex(0.8) scaley(1.4);
  }
  100%,
  100% {
    transform-origin: center top;
    transform: scalex(1) scaley(1);
  }
  85% {
    transform-origin: center top;
    transform: scalex(1.3) scaley(0.7);
  }
}

.ant-alert-success .ant-typography:not(.ant-typography-secondary) {
  color: #24d37d;
}

.chart_team{
    padding:20px;
    width: 100%
}
.chart_team .team {
    display: flex;
    align-items: center;
}
.chart_team .team img {
    width: 80px;
    height: 80px;
    margin-right: -30px;
    z-index: 50;
}
.chart_team .team-bar {
    flex-grow: 1;
    height: 35px;
    background-color: var(--bg-body);
    border-color: var(--color-border);
    border-radius: 15px;
    overflow: hidden;
    position: relative;
}
.chart_team .progress {
    height: 100%;
    border-radius: 15px;
}
.chart_team .label {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
}
.chart_team .bullish .progress {
    background: linear-gradient(to right, var(--bg-body), #00FF00);
    width: 52%;
}
.chart_team .bearish .progress {
    background: linear-gradient(to right, var(--bg-body), #FF0000);
    width: 50%;
}
.chart_team .moon .progress {
    background: linear-gradient(to right, var(--bg-body), #FFFF00);
    width: 40%;
}
.chart_team .lambo .progress {
    background: linear-gradient(to right, var(--bg-body), #0000FF);
    width: 27%;
}
.chart_team .scale {
    display: flex;
    justify-content: space-between;
    margin-left: 70px;
    font-size: 12px;
    margin-top: 5px;
}
