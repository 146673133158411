.kuber-staking-page .countdown {
  padding: 0;
  margin: 0;
}

.kuber-staking-page .countdown li {
  display: inline-block;
  margin: 0 8px;
  text-align: center;
}

.kuber-staking-page .countdown li h2 {
  color: #ffffff !important;
  background: var(--primary-color);
  box-shadow: 0px 0px 10px rgba(2, 215, 103, 0.7);
  border-radius: 10px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  margin-top: 0;
  margin-bottom: 8px;
}

.kuber-staking-page .carousel {
  overflow: hidden;
  padding: 0 48px;
}

.kuber-staking-page .carousel .ant-card {
  margin: 0 8px;
}
