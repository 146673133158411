.kuber-nft-page .cover {
  border-radius: 10px;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.kuber-nft-page .cover::before {
  content: "";
  position: absolute;
  width: 95%;
  height: 100%;
  background-image: inherit;
  background-size: cover;
  z-index: -1;
  filter: blur(12px);
  opacity: 0.6;
  left: 50%;
  transform: translate(-50%, 3%);
}

.kuber-nft-page .cover .ant-image {
  width: 100%;
}
