.kuber-daily-check-in-page .list {
  width: 100%;
  display: flex;
  min-width: 800px;
}

.kuber-daily-check-in-page .item {
  flex: 1;
  margin-inline: 8px;
  border-radius: 16px;
  padding-block: 16px;
  border: 2px solid var(--color-border);
}
.kuber-daily-check-in-page .item.focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 10px 1px rgba(36, 211, 125, 0.5);
}
.kuber-daily-check-in-page .item .ant-typography {
  font-size: 1.1rem;
}
.kuber-daily-check-in-page .item .ant-image-img {
  width: 50px;
}
